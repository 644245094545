import React from 'react';
import {graphql} from "gatsby";

import Header from "../../components/headers/Header";
import TransportRowDescription from "../../components/transport-row/TransportRowDescription";
import StaticMainRowGallery from "../../components/main-row/StaticMainRowGallery";
import Footer from "../../components/layouts/Footer";
import SEO from "../../components/seo/Seo";
import CallToAction from "../../components/layouts/CallToAction";
import TransportGoodToKnow from "../../components/transport-row/TransportGoodToKnow";
import TransportRowHeader from "../../components/transport-row/TransportRowHeader";

function IzvozniRov({data}) {
    return (
        <>
            <SEO title="Izvozni rov" description="Rudnik Sitarjevec Litija je sodobni podzemni muzej, ki obiskovalcem s svojo vsebino ponuja vpogled v raznolikost geološke naravne dediščine, svet netopirjev, pajkov in gliv, tako kakor tudi v bogastvo rudarskega izročila. Vse to doživite že v izvoznem rovu, ki je lahko dostopen za skoraj vse skupine obiskovalcev."/>
            <Header/>
            <TransportRowHeader/>
            <TransportRowDescription/>
            <TransportGoodToKnow/>
            <StaticMainRowGallery imagesData={data.source.edges}/>
            <CallToAction/>
            <Footer />
        </>
    );
}

export const pageQuery = graphql`
    query ImagesForTransportRowGallery {
        source: allFile(filter: {relativePath: {regex: "rudnik/izvozni-rov-galerija/"}}) {
            edges {
                node {
                    childImageSharp {
                        thumb: gatsbyImageData(
                            width: 200
                            height: 200
                            placeholder: BLURRED
                        )
                        full: gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
        }
    }
`;

export default IzvozniRov;