import React from 'react';
import {StaticImage} from "gatsby-plugin-image";

function TransportRowDescription(props) {
    return (
        <div>
            <div className="about-wrapper ptb--120 bg_color--1">
                <div className="container">
                    <div className="row align-items-center text-center text-lg-left">
                        <div className="col-lg-5 col-md-12 col-xl-5 order-1 mt-4 order-lg-0">
                            <div className="thumbnail">
                                <StaticImage
                                    src="../../assets/images/rudnik/vhod-v-rudnik-sitarjevec.jpg"
                                    alt="Vhod v Rudnik Sitarjevec"
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-xl-7 order-0 order-lg-1">
                            <div className="about-inner inner p-2 p-lg-4">
                                <div className="section-title">
                                    <h2 className="title">Vodeni ogledi <br /> Izvozni rov</h2>
                                    <p className="description">Rudnik Sitarjevec Litija je sodobni podzemni muzej, ki obiskovalcem s svojo vsebino ponuja
                                        vpogled v raznolikost geološke naravne dediščine, svet netopirjev, pajkov in gliv, tako kakor
                                        tudi v bogastvo rudarskega izročila. Vse to doživite že v izvoznem rovu, ki je lahko dostopen
                                        za skoraj vse skupine obiskovalcev.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-wrapper pb--120">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-9 col-xl-10 col-12 text-center text-lg-left">
                            <div className="about-inner inner">
                                <div className="section-title">
                                    <h2 className="title">Čaroben svet litijskega podzemlja</h2>
                                    <p className="description">Muzejska postavitev izhaja iz prostorskih danosti
                                        podzemne arhitekture, s poudarkom na detajlih stiliziranega kristala cinabarita, ki se kot rdeča nit vleče od pred vhodom postavljenega informacijskega stebra v notranjost rudnika.
                                        Obiskovalec doživi njegovo presenetljivo barvitost, je v stiku z okoljem nastanka kamnin in
                                        okamnin, nakitom in uporabo barv narejenih iz le-teh,...</p>
                                    <p className="description">
                                        Ob vodenju po Izvoznem rovu se zgodbe mineralov prepletajo z zgodbami rudarjev. Spoznali
                                        boste način rudarjenja v zadnjem obdobju delovanja rudnika. Ogledali si boste zbirko
                                        rudarskega orodja, slišali kako rudarsko anekdoto in legendo o sveti Barbari, zavetnici
                                        rudarjev.  Svoje raziskovanje boste nadaljevali z ogledom skoraj vertikalnega odkopnega
                                        polja s še nerazkrito žilo barita, rekonstrukcijo lesenega podporja, tračnic z rudarskim
                                        vozičkom ter bogato zbirko kamnin in rud Sitarjevca. V rudniški dvorani si boste ogledali
                                        kratek film o rudniku.</p>
                                    <p className="description">
                                        Preplet naravnih znamenitosti in umetniškega ustvarjanja doseže vrhunec z rekonstrukcijo in
                                        interpretacijo paleookolja ter pomena svetlobe v rudniku Sitarjevec. Umetniki so slednje
                                        izrazili s svetlobnim performansom neobičajnih svetil, kresničk, ki so jih obesili pod strop
                                        rudniškega jaška.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TransportRowDescription;